import axios from '../../axios'

export default {
  getBrands(params = {}) {
    return axios.get(`/master-data/brands`, { params: { limit: 1000, ...params } })
  },
  createBrand(payload = {}) {
    return axios.post(`/master-data/brands`, payload)
  },
  updateBrand(payload = {}) {
    const id = payload.id
    delete payload.id
    return axios.put(`/master-data/brands/${id}`, payload)
  },
  deleteBrand(id) {
    return axios.delete(`/master-data/brands/${id}`)
  },
  getModels(params = {}) {
    return axios.get(`/master-data/models`, { params: { limit: 1000, ...params } })
  },
  createModel(payload = {}) {
    return axios.post(`/master-data/models`, payload)
  },
  updateModel(payload = {}) {
    console.log('http updateModel: ', payload);
    const id = payload.id
    delete payload.id
    return axios.put(`/master-data/models/${id}`, payload)
  },
  deleteModel(id) {
    return axios.delete(`/master-data/models/${id}`)
  },
  getProvinces(params = {}) {
    return axios.get(`/master-data/provinces`, { params: { limit: 1000, ...params } })
  },
  getInsuranceCompanies(params = {}) {
    return axios.get(`/master-data/insurance-companies`, { params: { limit: 1000, ...params } })
  },
  getTagProducts(params = {}) {
    return axios.get(`/master-data/tag-products`, { params: { limit: 1000, ...params } })
  },
  getServices(params = {}) {
    return axios.get(`/services`, { params: { limit: 100, ...params } })
  },
}
