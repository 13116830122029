import service from '../../http/requests/masterData'

const actions = {
  getBrands({ commit }, payload) {
    service.getBrands(payload).then((response) => {
      commit('SET_BRANDS', response.data)
    })
  },
  createBrand(context, payload) {
    return service.createBrand(payload)
  },
  updateBrand(context, payload) {
    return service.updateBrand(payload)
  },
  deleteBrand(context, payload) {
    return service.deleteBrand(payload)
  },
  getModels({ commit }, payload) {
    service.getModels(payload).then((response) => {
      commit('SET_MODELS', response.data)
    })
  },
  createModel(context, payload) {
    return service.createModel(payload)
  },
  updateModel(context, payload) {
    return service.updateModel(payload)
  },
  deleteModel(context, payload) {
    return service.deleteModel(payload)
  },
  getProvinces({ commit }, payload) {
    service.getProvinces(payload).then((response) => {
      commit('SET_PROVINCES', response.data)
    })
  },
  getInsuranceCompanies({ commit }, payload) {
    service.getInsuranceCompanies(payload).then((response) => {
      commit('SET_INSURANCE_COMPANIES', response.data)
    })
  },
  setModelsEmpty({ commit }) {
    commit('SET_MODELS', [{ title_en: 'N/A' }])
  },
  getTagProducts({ commit }, payload) {
    service.getTagProducts(payload).then((response) => {
      commit('SET_TAG_PRODUCTS', response.data)
    })
  },
}

export default actions
