import axios from '../../axios'

export default {
  fetchBatches(queryString = {}) {
    return axios.get('/batches', { params: queryString })
  },

  fetchBatch(id) {
    return axios.get(`/batches/${id}`)
  },

  export(id) {
    return axios.post(`/batches/${id}/export`)
  },

  insert(payload) {
    return axios.post(`/batches/`, payload)
  },

  update(id, payload) {
    return axios.put(`/batches/${id}`, payload)
  },

  deleteRecord(id) {
    return axios.delete(`/batches/${id}`)
  },
}
