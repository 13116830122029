import axios from '../../axios'

export default {
  fetchQRcodes(queryString = {}) {
    return axios.get('/qrcodes', { params: queryString })
  },

  fetchQRcode(id) {
    return axios.get(`/qrcodes/${id}`)
  },

  getRegister(code) {
    return axios.get(`/qrcodes/${code}/register`)
  },

  checkAuthcode(authcode) {
    return axios.get(`/qrcodes/check-authcode?code=${authcode}`)
  },

  request({ code, payload }) {
    return axios.post(`/qrcodes/${code}/request`, payload)
  },

  getRequest({ code, clientRequestId }) {
    return axios.get(`/qrcodes/${code}/request?clientRequestId=${clientRequestId}`)
  },

  stampScanTransaction({ code, payload }) {
    return axios.post(`/qrcodes/${code}/scan`, payload)
  },

  deleteRecord(id) {
    return axios.delete(`/qrcodes/${id}`)
  },

  transferOwner({ code, payload }) {
    return axios.post(`/qrcodes/${code}/transfer`, payload)
  },
  resetQRCode(code) {
    return axios.post(`/qrcodes/${code}/reset`)
  },
}
