import service from '../../http/requests/customer'

const actions = {
  fetchCustomers({ commit }, payload = {}) {
    service.fetchCustomers(payload).then((res) => {
      commit('SET_CUSTOMERS', res.data)
    })
  },
  fetchCustomer(context, id) {
    return service.fetchCustomer(id)
  },
  getRegister(context, payload) {
    return service.getRegister(payload)
  },
  updateRegister(context, { customerId, registerId, body }) {
    return service.updateRegister(customerId, registerId, body)
  },
  deleteRegister(context, payload) {
    return service.deleteRegister(payload)
  },
  getCustomerInfo({ commit }, payload) {
    service.getRegisters(payload).then((response) => {
      commit('SET_CUSTOMER_INFO', response.data)
    })
  },
  register(context, { customerId, body }) {
    return service.register(customerId, body)
  },
  getSetting(context, id) {
    return service.getSetting(id)
  },
  updateSetting(context, { customerId, payload }) {
    return new Promise((resolve, reject) => {
      service
        .updateSetting(customerId, payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
}

export default actions
