import service from '../../http/requests/qrcode'

const actions = {
  fetchQRcodes({ commit }, payload = {}) {
    service.fetchQRcodes(payload).then((res) => {
      commit('SET_QRCODES', res.data)
    })
  },

  getRegister(context, code) {
    return service.getRegister(code)
  },

  checkAuthcode(context, code) {
    return service.checkAuthcode(code)
  },

  request(context, payload) {
    return service.request(payload)
  },

  getRequest(context, payload) {
    return service.getRequest(payload)
  },

  stampScanTransaction(context, payload) {
    return service.stampScanTransaction(payload)
  },

  transferOwner(context, payload) {
    return service.transferOwner(payload)
  },

  resetQRCode(context, code) {
    return service.resetQRCode(code)
  },
}

export default actions
