import service from '../../http/requests/service'

export default {
  fetchServices({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      service
        .fetchServices(payload)
        .then((res) => {
          commit('SET_SERVICES', res.data)
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
}
