import state from './moduleBatchState'
import mutations from './moduleBatchMutations'
import actions from './moduleBatchActions'
import getters from './moduleBatchGetters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}