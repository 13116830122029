const mutations  = {

  SET_QRCODES(state, value){ state.qrcodes = value },

  SET_CLIENT_REQUEST_ID(state, value){ 
    state.clientRequestId = value
    localStorage.setItem("clientRequestId", value)
  }
  
}

export default mutations