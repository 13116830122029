/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

// Module
import moduleAuth from './auth/moduleAuth'
import moduleDashboard from './dashboard'
import moduleCustomer from './customer'
import moduleMasterData from './masterData'
import moduleQRcode from './qrcode/moduleQRcode'
import moduleBatch from './batch/moduleBatch'
import moduleService from './service'
import moduleSetting from './setting'

export const strict = false
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    dashboard: moduleDashboard,
    customer: moduleCustomer,
    masterData: moduleMasterData,
    qrcode: moduleQRcode,
    batch: moduleBatch,
    service: moduleService,
    setting: moduleSetting,
  },
  strict: process.env.NODE_ENV !== 'production',
})
