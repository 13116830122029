export default {
  services: (state) => {
    return state.services
  },
  serviceOptions: (state) => {
    return state.services ? state.services.rows.map((v) => ({ label: v.name_en, value: v.id })) : []
  },
  getServiceName: (state) => (serviceId) => {
    return state.services ? state.services.rows.find((v) => v.id === serviceId).name_en : ''
  },
}
