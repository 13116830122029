import axios from "../../http/axios"

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  SET_LOCAL_STORAGE(state, data){
    localStorage.setItem("accessToken", data.accessToken)
    localStorage.setItem("userInfo", JSON.stringify(data.userData||{}))
  }
}
