import jwt from "../../http/requests/auth/jwt"
import router from '@/router'

export default {
  // JWT
  loginJWT({ commit }, payload) {
    
    return new Promise((resolve,reject) => {
      jwt.login(payload.userDetails.username, payload.userDetails.password)
        .then(response => {
          // Set accessToken
          localStorage.setItem("accessToken", response.data.accessToken)
          // Update user details
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})
          // Set bearer token in axios
          commit("SET_BEARER", response.data.accessToken)
          resolve(response)
        })
        .catch(error => { 
          reject(error) 
        })
    })
  },
  registerUserJWT({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve,reject) => {

      // Check confirm password
      if(password !== confirmPassword) {
        reject({message: "Password doesn't match. Please try again."})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          commit("SET_LOCAL_STORAGE", response.data)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  refreshToken({ commit }){
    jwt.refreshToken().then(res => { 
      commit('SET_LOCAL_STORAGE', res.data)
    })
  },
  logout(){
    localStorage.clear()
    router.push('/login')
  }
}
