const getters = {
  masterDatas: function(state) {
    return {
      brands: state.brands,
      models: state.models,
      provinces: state.provinces,
      insuranceCompanies: state.insuranceCompanies,
      tagProducts: state.tagProducts,
    }
  },

  brands: (state) => state.brands,

  models: (state) => state.models,

  provinces: (state) => state.provinces,

  insuranceCompanies: (state) => state.insuranceCompanies,

  brandOption: (state) => state.brands.rows ? state.brands.rows.filter((v) => v.title_en !== 'N/A').map((v) => ({
    label: v.title_en,
    value: v.id,
  })) : []
}

export default getters
