const mutations = {
  SET_BRANDS(state, value) {
    state.brands = value
  },

  SET_MODELS(state, value) {
    state.models = value
  },

  SET_PROVINCES(state, value) {
    state.provinces = value
  },

  SET_INSURANCE_COMPANIES(state, value) {
    state.insuranceCompanies = value
  },

  SET_TAG_PRODUCTS(state, value) {
    state.tagProducts = value
  },
}

export default mutations
