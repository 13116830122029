/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
import VueHtmlToPaper from 'vue-html-to-paper'
import VueHtml2Canvas from 'vue-html2canvas'
import VueMask from 'v-mask'

Vue.use(VueMask);

Vue.use(VueHtml2Canvas)

Vue.use(VueHtmlToPaper)

Vue.use(Vuesax)

// axios
import axios from './axios'
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// My Style
import '@/assets/css/style.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Environment
Vue.config.productionTip = false

// App Version
Vue.prototype.$appVersion = process.env.VUE_APP_VERSION || "1.0.0"
Vue.prototype.$baseApiUrl = process.env.VUE_APP_API_BASE_URL
Vue.prototype.$config = {
  appVersion:  process.env.VUE_APP_VERSION || "1.0.0",
  baseApiUrl:  process.env.VUE_APP_API_BASE_URL,
  ssUrl: process.env.VUE_APP_S3_URL,
  ssApiKey: process.env.VUE_APP_S3_API_KEY,
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
