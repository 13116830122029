import service from '../../http/requests/setting'

export default {
  async getSettingClient() {
    try {
      return await  service.getSettingClient()
    } catch (error) {
      Promise.reject(error)
    }
  },
}
