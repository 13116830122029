import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: { auth: true },
        },
        // Batch
        {
          path: '/manage/batches',
          name: 'batche-list',
          component: () => import('./views/pages/managing/batch/BatchList.vue'),
          meta: { auth: true },
        },
        {
          path: '/manage/batches/:id',
          name: 'qrcode-list',
          component: () => import('./views/pages/managing/qrcode/QRcodeList.vue'),
          meta: { auth: true },
        },
        {
          path: '/manage/batches/preview/:id',
          name: 'qrcode-preview',
          component: () => import('./views/pages/managing/qrcode/QRCodePreview.vue'),
          meta: { auth: true },
        },
        {
          path: '/manage/companies',
          name: 'company-list',
          component: () => import('./views/pages/managing/company/CompanyList.vue'),
          meta: { auth: true },
        },
        {
          path: '/manage/customers',
          name: 'customer-list',
          component: () => import('./views/pages/managing/customer/CustomerList.vue'),
          meta: { auth: true },
        },
        {
          path: '/manage/customers/:id',
          name: 'customer-profile',
          component: () => import('./views/pages/managing/customer/profile/Profile.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Customers', url: '/manage/customers' },
              { title: 'Profile', active: true },
            ],
            pageTitle: 'Customer Profile',
            rule: 'editor',
          },
        },
        // Master Data
        {
          path: '/master-data/brands',
          name: 'brand-list',
          component: () => import('./views/pages/managing/master-data/brand/BrandList.vue'),
          meta: { auth: true },
        },
        {
          path: '/master-data/models',
          name: 'brand-list',
          component: () => import('./views/pages/managing/master-data/model/ModelList.vue'),
          meta: { auth: true },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/scan/:code',
          name: 'scan',
          component: () => import('@/views/pages/information/Scan.vue'),
        },
        {
          path: '/information',
          name: 'information',
          component: () => import('@/views/pages/information/Information.vue'),
        },
        {
          path: '/register-input-code',
          name: 'register-input-code',
          component: () => import('@/views/pages/information/InputCode.vue'),
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/information/Register.vue'),
        },
        {
          path: '/register-tag',
          name: 'register-tag',
          component: () => import('@/views/pages/information/RegisterTag.vue'),
        },
        {
          path: '/setting',
          name: 'setting',
          component: () => import('@/views/pages/information/Setting.vue'),
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/token-expired',
          name: 'token-expired',
          component: () => import('@/views/pages/TokenExpired.vue'),
        },
        {
          path: '/access-denied',
          name: 'access-denied',
          component: () => import('@/views/pages/AccessDenied.vue'),
        },
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      component: () => import('@/views/pages/Error404.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.auth.isUserLoggedIn()) {
    return store.dispatch('auth/logout')
  } else {
    if (to.name == 'login' && store.state.auth.isUserLoggedIn()) next('/')
    else next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
