const getters  = {

  qrcodes: state => state.qrcodes,
  clientRequestId: state => {
    return state.clientRequestId || localStorage.getItem("clientRequestId")
  }
    
  
  
}

export default getters