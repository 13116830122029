
import axios from "../../axios"

export default {
  fetchCustomers(queryString = {}){
    return axios.get('/customers', {params: queryString})
  },
  fetchCustomer(id){
    return axios.get(`/customers/${id}`)
  },
  getRegister({customerId, registerId}){
    return axios.get(`/customers/${customerId}/register/${registerId}`)
  },
  updateRegister(customerId, registerId, payload){
    return axios.put(`/customers/${customerId}/register/${registerId}`, payload)
  },
  getRegisters(customerId){
    return axios.get(`/customers/${customerId}/registers`)
  },
  register(customerId, payload){
    return axios.post(`/customers/${customerId}/register`, payload)
  },
  deleteRegister({customerId, registerId}){
    return axios.delete(`/customers/${customerId}/register/${registerId}`)
  },
  getSetting(customerId){
    return axios.get(`/customers/${customerId}/setting`)
  },
  updateSetting(customerId, payload){
    return axios.put(`/customers/${customerId}/setting`, payload)
  },
}