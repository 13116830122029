import axios from '../../axios'

export default {
  fetchServices(queryString = {}) {
    return axios.get('/services', { params: queryString })
  },
  getServices(id) {
    return axios.get(`/services/${id}`)
  },
}
