import service from '../../http/requests/batch'

const actions = {
  fetchBatches({ commit }, payload = {}) {
    service.fetchBatches(payload).then((res) => {
      commit('SET_BATCHES', res.data)
    })
  },

  export(context, id) {
    return service.export(id)
  },

  insert(context, payload) {
    return service.insert(payload)
  },

  update(context, { id, payload }) {
    return service.update(id, payload)
  },
}

export default actions
