const mutations = {

  SET_CUSTOMERS(state, value){ state.customers = value},

  SET_CUSTOMER_INFO(state, value){ state.customerInfo = value},

  SET_REGISTER(state, value){ state.register = value},
  
}

export default mutations