import jwt from 'jsonwebtoken'

export default {
  isUserLoggedIn: () => {
    let accessToken = localStorage.getItem('accessToken')
    try {
      let secret = process.env.VUE_APP_SECRET
      let decoded = jwt.verify(accessToken, secret)
      if (!decoded.username) return false
    } catch (err) {
      return false
    }
    if (!accessToken || !localStorage.getItem('userInfo')) return false
    let userInfo = jwt.decode(accessToken)
    let _now = Math.ceil(new Date().getTime() / 1000)
    return _now < userInfo.exp
  },
}
