import state from './moduleQRcodeState'
import mutations from './moduleQRcodeMutations'
import actions from './moduleQRcodeActions'
import getters from './moduleQRcodeGetters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}