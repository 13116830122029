import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import jwt from 'jsonwebtoken'
import router from '@/router'

let isRefreshingToken = false

const checkRefreshToken = () => {
  let accessToken = localStorage.getItem('accessToken')
  if (!accessToken) return false
  const userInfo = jwt.decode(accessToken)
  const today = new Date()
  const now = Math.ceil(today.getTime() / 1000)
  const diff = (userInfo.exp - now) / 60
  return diff <= 5 && diff >= 0
}

export default {
  init() {
    axios.interceptors.request.use(
      function(config) {
        config.headers['Authorization'] =
          config.headers.Authorization || `Bearer ${localStorage.getItem('accessToken')}`

        //RefreshToken
        if (checkRefreshToken() && !isRefreshingToken) {
          isRefreshingToken = true
          store.dispatch('auth/fetchAccessToken').then((res) => {
            store.commit('auth/SET_LOCAL_STORAGE', res.data)
            isRefreshingToken = false
          })
        }

        return config
      },
      function(error) {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        const { response } = error

        if (response && response.status === 401 && response.data.error.message == 'jwt expired') {
          if (error.config.url.startsWith('/customer')) {
            return router.push({ name: 'token-expired' })
          }
          return store.dispatch('auth/logout')
        }

        return Promise.reject(response.data.error || error)
      }
    )
  },
  login(username, pwd) {
    return axios.post('/users/login', { username: username, password: pwd })
  },
  registerUser(name, email, pwd) {
    return axios.post('/users/register', { displayName: name, email: email, password: pwd })
  },
  refreshToken() {
    console.log('In refreshToken()')
    return axios.post('/users/refresh-token', { accessToken: localStorage.getItem('accessToken') })
  },
}
